:root {
    font-size: 14px;
}

.navbar-logo {
    max-height: 48px;
}

@media (min-width: 1400px) {
	.navbar-logo {
		margin-right: 240px;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.navbar-logo {
		margin-right: 140px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-logo {
		margin-right: 45px;
	}
}

.logo-sus {
	height: 45px;
	margin-left: auto;
	margin-right: 0;
    display: inline;
}

.nav-item:focus>.nav-link, .nav-item:hover>.nav-link {
    color: var(--bs-link-color)!important;
    background-color: inherit!important;
}

.nav-link:focus, .nav-link:hover {
    background-color: inherit!important;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: var(--bs-link-color)!important;
    background-color: inherit!important;
}

.min-vh-55 {
    min-height: 55vh !important;
}

@media (min-width: 576px) {
    .min-vh-sm-55 {
        min-height: 55vh !important;
    }
}

@media (min-width: 768px) {
    .min-vh-md-55 {
        min-height: 55vh !important;
    }
}

@media (min-width: 992px) {
    .min-vh-lg-55 {
        min-height: 55vh !important;
    }
}

:target {
    display: block;    
    position: relative;     
    top: -70px;
    visibility: hidden;
}

.accordion-button {
    font-size: 1rem;
}

.blog-body img {
    max-width: 100%;
}

label {
    margin-bottom: 0.5rem;
    font-size: .875rem;
    font-weight: 500;
}

.dropdown-item:hover::after {
    opacity: 0;
    right: 0.4375rem;
}

.bg-gray {
    background-color: rgb(235, 240, 243);
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.75rem;
}

.bg-gray p:last-of-type {
    margin-bottom: 0px;
}

.text-small {
    font-size: 80%;
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1320px;
    }
}

[data-bs-toggle="collapse"].collapsed .if-not-collapsed {
    display: none;
}

[data-bs-toggle="collapse"]:not(.collapsed) .if-collapsed {
    display: none;
}

#c-txt {
    text-align: justify!important;
}

@media (min-width: 1400px) {
    .w-xxl-40 {
        width: 40%!important;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .w-xl-50 {
        width: 47%!important;
    }
}

.faq .text-primary p, .faq .text-primary ul {
    color: var(--bs-primary);
}

.nav-segment .nav-link {
    color: #51596c;
}

.nav-segment .nav-link.active {
    background: white!important;
}

.nav-segment .nav-link.active:hover {
    color: #2d374b!important;
}

.nav-segment .nav-link {
    color: white;
}

.nav-segment .nav-link:hover {
    color: white!important;
}

.page-section ul, .webinar-text ul {
    list-style: none;
    padding-left: 1rem;
    margin-left: 5px;
}

.page-section ul li::before, .webinar-text ul li::before {
    content: "✓";
    color: #ee3477;
    font-weight: bold;
    display: inline-block;
    width: 15px;
    margin-left: -15px;
    font-size: 80%;
  }

  .page-section li > ul li::before {
    content: "◦"!important;
}